<!--
 * @Author: your name
 * @Date: 2020-12-24 11:27:47
 * @LastEditTime: 2021-07-14 11:03:48
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/views/Home/module/telPopup.vue
-->
<template>
  <div class="callPopup-page">
    <van-popup
      v-model="showCallFlag"
      round
      :closeable="false"
      :style="{ width: '80%' }"
      :close-on-click-overlay="false"
      @click-overlay="cancelCall"
    >
      <div class="popup-content">
        <!-- <p>您是否要拨打水司客服热线?</p>
        <span>{{ telPhone }}</span> -->
        <p>客服电话：0635-7307307 <a :href="'tel:0635-7307307'" class="tel-btn">直接拨号</a></p>
        <p></p>
      </div>
      <!-- <div class="btnContent">
        <span @click="cancelCall">取消</span>
        <span><a :href="'tel:' + telPhone">拨打</a></span>
      </div> -->
    </van-popup>
  </div>
</template>

<script>
import serverconfig from '@/api/server.config';
const CUSTOMRT_TEL = serverconfig.CUSTOMRT_TEL;
export default {
  name: 'TelPopup',
  props: {
    showCallFlag: Boolean,
    default: () => {
      return false;
    }
  },
  data() {
    return {
      telPhone: CUSTOMRT_TEL
    };
  },
  methods: {
    cancelCall() {
      this.$emit('clickCancelTel');
    }
  }
};
</script>

<style lang="less" scoped>
.callPopup-page {
  // 弹出框
  .popup-content {
    .tel-btn{
      border: 1px solid #0067E6;
      border-radius: 10px;
      color: #0067E6;
      padding: 4px 6px;
      margin-left: 10px;
      font-size: 14px;
    }
    p {
      margin: 0px;
      margin-top: 30px;
      text-align: center;
      font-size: 16px;
      color: #3f3f3f;
    }
    span {
      display: block;
      // color: #0067E6;
      color: #222f45;
      text-align: center;
      padding-top: 15px;
      padding-bottom: 16px;
      font-size: 20px;
    }
  }
  .btnContent {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    span {
      font-size: 14px;
      width: 50%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      a {
        color: #0067e6;
      }
    }
    span:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}
</style>
