<template>
  <div class="myWater">
    <div class="myWater-content">
      <span class="title">我的用水</span>
      <div class="myWater-info">
        <!-- <div>:autoplay="3000"</div> -->
        <van-swipe class="my-swipe" indicator-color="#A2B0C6" show-indicators :autoplay="3000">
          <van-swipe-item
            v-for="(item, index) in topDataInfo"
            :key="index"
            @click="waterCardInfor(item)"
          >
            <span class="rightSpan" style="margin-bottom: -15px;" v-if="item.waterMeterStatus">
              {{ item.waterMeterStatus }}
            </span>
            <!--  是否认证 -->
            <div
              :class="isRealNameFlag ? 'current-author-title golden-title' : 'current-author-title'"
            >
              <van-icon name="location" />
              <span style="width: 100px">{{ item.labelName }}</span>
            </div>
            <div class="current-author-payInfo">
              <div class="van-ellipsis">
                <span v-if="item.wuserName && item.wuserName.length > 0"
                  >{{ item.wuserName }} </span
                >

                「{{ item.wuserNo }}-{{ item.wuserAddress }}」
              </div>
            </div>
            <!-- 是否认证 -->
            <div
              :class="
                isRealNameFlag ? 'current-authorInfo golden-authorInfo' : 'current-authorInfo'
              "
            >
              <ul>
                <li>
                  <p>{{ item.monthWaterVolume }}<i>吨</i></p>
                  <em>{{ item.month }}月份水量</em>
                </li>
                <li>
                  <p>{{ item.yearWaterVolume }}<i>吨</i></p>
                  <em>今年水量</em>
                </li>
                <li>
                  <p>{{ isActual(item.depositBalance) }}<i>元</i></p>
                  <em>余额</em>
                  <span v-if="item.depositBalance <= 0">待充值</span>
                </li>
              </ul>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div :class="isRealNameFlag ? 'current-author-grid golden' : 'current-author-grid'">
        <van-grid column-num="5" :border="false">
          <van-grid-item
            use-slot
            v-for="(item, index) in dataInfo"
            :key="index"
            @click="waterService(item)"
          >
            <em><img :src="item.icon" alt=""/></em>
            <span class="name">{{ item.name }}</span>
            <i v-if="item.badgeInfo">{{ item.badgeInfo }}</i>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { isAmount } from '@/utils/validator';
export default {
  name: 'MyWaterBind',
  props: {
    topDataInfo: {
      type: Array,
      require: true,
      default: () => {
        return [];
      }
    },
    dataInfo: {
      type: Array,
      require: true,
      default: () => {
        return [];
      }
    },
    isRealNameFlag: {
      type: Boolean,
      require: true,
      default: () => {
        return false;
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    isActual() {
      return (val) => {
        return isAmount(val);
      };
    }
  },
  methods: {
    // 用水信息跳转
    waterCardInfor(data) {
      this.$router.push({
        name: 'WaterAccount',
        path: '/WaterAccount',
        query: {
          custWaterUserRelId: data.custWaterUserRelId,
          labelName: data.labelName,
          wuserNo: data.wuserNo,
          isWaterUser: data.isWaterUser
        }
      });
    },
    // 用水业务跳转
    async waterService(data) {
      if (data) {
        this.$router.push({
          path: data.pathUrl
        });
      }
    }
  },
  watch: {
    topDataInfo: {
      immediate: true,
      handler(val) {
        this.topDataInfo = val;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.myWater {
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 10px;
  padding-bottom: 16px;
  .myWater-content {
    .title {
      display: inline-block;
      width: 100%;
      font-weight: 700;
      text-align: left;
      // padding-bottom: 8px;
      font-family: PingFangSC-Semibold;
      font-size: 16px;
      color: #222f45;
      letter-spacing: 0;
      margin: 15px 16px 16px 16px;
      // padding-top: 10px;
    }
    .myWater-info {
      margin: 0px 16px;
      .my-swipe {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 8px 12px 0 rgba(138, 178, 177, 0.3);
        background-image: linear-gradient(180deg, #eceae4 0%, #ffffff 50%, #ffffff 96%);
        padding-bottom: 10px;
        .rightSpan {
          float: right;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #ffffff;
          letter-spacing: 0;
          background: rgba(255, 0, 0, 0.54);
          border-radius: 0 8px 0 8px;
          padding: 2px 10px 2px 9px;
        }
        .current-author-title {
          padding-left: 12px;
          text-align: left;
          float: left;
          width: 100%;
          margin-top: 12px;
          span {
            text-align: left;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #5f97f4;
            letter-spacing: 0;
            float: left;
            font-weight: 600;
          }
          .van-icon {
            font-size: 16px;
            color: #5f97f4;
            vertical-align: middle;
            float: left;
            margin-top: 2px;
            margin-right: 6px;
          }
        }
        .current-author-payInfo {
          font-size: 12px;
          color: #999999;
          margin: 0px 15px 0px;
          padding-top: 5px;
          padding-bottom: 5px;
          text-align: left;
          position: relative;
          margin-top: 3px;
          div {
            display: inline-block;
            height: 28px;
            line-height: 30px;
            width: calc(100% - 5px);
            // padding-bottom: 4px;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #5c6775;
            letter-spacing: -0.36px;
            vertical-align: middle;
          }
        }
        .current-authorInfo {
          width: 100%;
          display: inline-block;
          margin-top: 0px;
          margin-bottom: 12px;
          margin-top: 10px;
          ul {
            li {
              width: calc(100% / 3 - 4px);
              display: inline-block;
              // border-right: 1px solid #e7e7e7;
              position: relative;
              position: relative;
              text-align: center;
              p {
                margin: 0px;
                padding: 0px;
                margin-bottom: -8px;
                font-family: DINAlternate-Bold;
                font-size: 20px;
                color: #5f97f4;
                letter-spacing: 1.43px;
                padding-bottom: 5px;
                i {
                  font-style: normal;
                  font-size: 12px;
                }
              }
              em {
                color: #98989f;
                font-style: normal;
                font-weight: 600;
                font-family: PingFangSC-Medium;
                font-size: 12px;
                color: #5c6775;
                letter-spacing: 0;
              }
              span {
                background-color: #ff5858;
                border-top-left-radius: 50px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                position: absolute;
                top: -10px;
                left: 55px;
                padding: 0px 1px;
                font-style: normal;
                font-family: PingFangSC-Regular;
                font-size: 8px;
                color: #ffffff;
                letter-spacing: 0;
                padding: 0px 4px;
              }
            }
            li::after {
              position: absolute;
              box-sizing: border-box;
              content: ' ';
              pointer-events: none;
              top: -15px;
              right: 0;
              bottom: -15px;
              border-right: 1px solid #ebedf0;
              -webkit-transform: scaleY(0.5);
              transform: scaleY(0.5);
            }
            li:last-child::after {
              position: absolute;
              box-sizing: border-box;
              content: ' ';
              pointer-events: none;
              top: -15px;
              right: 0;
              bottom: -15px;
              border-right: 0px solid transparent;
              -webkit-transform: scaleY(0.5);
              transform: scaleY(0.5);
            }
          }
        }
        // 金色的
        .golden-title {
          span {
            color: #d5a864;
          }
          .van-icon {
            color: #d5a864;
          }
        }
        .golden-authorInfo {
          p {
            color: #d5a864 !important;
          }
        }
        .van-swipe__indicators {
          bottom: 6px;
        }
        /deep/.van-swipe__indicator {
          background-color: #999999;
          width: 4px;
          height: 4px;
        }
      }
    }
    .current-author-grid {
      margin: 22px 16px 0px 16px;
      em {
        display: inline-block;
        width: 44px;
        height: 44px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #5c6775;
        letter-spacing: 0;
      }
      i {
        position: absolute;
        top: 0px;
        right: 0px;
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        padding: 1px 3px;
        font-style: normal;
        font-family: PingFangSC-Regular;
        font-size: 8px;
        color: #ffffff;
        letter-spacing: 0;
        background-color: #5f97f4;
      }
    }
    .golden {
      i {
        background-color: #d5a864;
      }
    }
  }
}
</style>
