<!--
 * @Author: your name
 * @Date: 2020-10-16 16:55:00
 * @LastEditTime: 2021-07-14 15:49:20
 * @LastEditors: yangliao
 * @Description: 我的反馈
 * @FilePath: /netHallOfficialAccounts/src/views/Home/module/feedBack.vue
-->
<template>
  <div class="feedback">
    <div class="feedback-content">
      <van-cell title="我要反馈" is-link @click="gotoMyFeedback">
        <span :style="feedBackNum > 0 ? 'margin-right: 25px' : ''">我的反馈</span>
        <em v-if="feedBackNum > 0">{{ feedBackNum }}</em>
      </van-cell>

      <div class="feedback-info">
        <ul>
          <li :class="isRealNameFlag ? 'goldenLeftLi' : ''" @click="handerReport">
            <p>违章举报</p>
            <em>确认可获奖励</em>
          </li>
          <li :class="isRealNameFlag ? 'goldenLeftLi' : ''" @click="handerRepair">
            <p>报事报修</p>
            <em>欢迎您来申请</em>
          </li>
          <li :class="isRealNameFlag ? 'goldenRightLi' : ''" @click="handerProposal">
            <p>意见反馈</p>
            <em>欢迎您来吐槽</em>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant';
export default {
  name: 'FeedBack',
  props: {
    isRealNameFlag: {
      type: Boolean,
      require: true,
      default: () => {
        return false;
      }
    },
    feedBackNum: {
      type: Number,
      require: true,
      default: () => {
        return 0;
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    handerReport() {
      this.$router.push({ path: '/Feedback/ViolationReport', query: { state: 'add' } });
      // Dialog.confirm({
      //   title: ``,
      //   message: `暂未开通此功能`,
      //   showCancelButton: false,
      //   confirmButtonText: '确定',
      //   confirmButtonColor: '#3C7EF0'
      // });
    },
    handerRepair() {
      this.$router.push({ path: '/Feedback/RepairApply', query: { state: 'add' } });
    },
    handerProposal() {
      this.$router.push({ path: '/Feedback/Proposal', query: { state: 'add' } });
      // Dialog.confirm({
      //   title: ``,
      //   message: `暂未开通此功能`,
      //   showCancelButton: false,
      //   confirmButtonText: '确定',
      //   confirmButtonColor: '#3C7EF0'
      // });
    },
    gotoMyFeedback() {
      this.$router.push({ path: '/Feedback/MyFeedBack' });
    }
  }
};
</script>

<style lang="less" scoped>
.feedback {
  background-color: #ffffff;
  padding-bottom: 18px;
  .feedback-content {
    text-align: left;
    margin-top: 10px;
    padding-top: 10px;
    .feedback-info {
      width: 100%;
      display: inline-block;
      background: #fff;
      ul {
        margin: 0px 16px;
        display: flex;
        li {
          display: inline-block;
          width: calc(100% / 2 - 10px);
          text-align: left;
          padding-left: 16px;
          padding-top: 27px;
          padding-bottom: 27px;
          p {
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #5c6775;
            letter-spacing: 0;
            margin: 0px;
          }
          em {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #5c6775;
            letter-spacing: 0;
            font-style: normal;
          }
        }
        // 蓝色背景
        li:first-child {
          background: url('../../../assets/pic_banner_blue_left2@2x.png') no-repeat 90% 0;
          background-size: contain;
        }
        li:nth-child(2) {
          background: url('../../../assets/pic_banner_blue_right2@2x.png') no-repeat 90% 0;
          background-size: contain;
        }
        li:last-child {
          background: url('../../../assets/pic_banner_blue_right2@2x.png') no-repeat 90% 0;
          background-size: contain;
        }
        // 金色背景
        .goldenLeftLi:first-child {
          background: url('../../../assets/pic_banner_gold_left2@2x.png') no-repeat 90% 0;
          background-size: contain;
        }
        .goldenLeftLi:nth-child(2) {
          background: url('../../../assets/pic_banner_gold_right2@2x.png') no-repeat 90% 0;
          background-size: contain;
        }
        .goldenRightLi:last-child {
          background: url('../../../assets/pic_banner_gold_right2@2x.png') no-repeat 90% 0;
          background-size: contain;
        }
      }
    }
  }
}
</style>
