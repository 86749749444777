<!--
 * @Author: your name
 * @Date: 2020-10-16 17:28:21
 * @LastEditTime: 2020-12-11 09:48:29
 * @LastEditors: Please set LastEditors
 * @Description: 未绑定状态-我的用水
 * @FilePath: \nethallofficialaccounts\src\views\Home\module\unboundMyWater.vue
-->
<template>
    <div class="myWater">
        <div class="myWater-content">
            <span class="title">我的用水</span>
            <!-- 未绑定 -->
            <div :class="isRealNameFlag ? 'current-author-grid golden': 'current-author-grid'">
                <van-grid column-num="5" :border="false">
                    <van-grid-item use-slot v-for="(item, index) in dataInfo" :key="index"  @click="waterService(item)">
                        <em><img :src="item.icon" alt=""></em>
                        <span class="name">{{item.name}}</span>
                        <i v-if="item.badgeInfo">{{item.badgeInfo}}</i>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import { mapState } from "vuex";
export default {
    name: 'MyWaterUnbound',
    props: {
        dataInfo: {
            type: Array,
            require: true,
            default: () => {
                return []
            }
        },
        isRealNameFlag: {
            type: Boolean,
            require: true,
            default: () => {
                return false
            }
        }
    },
    computed: {
        ...mapState("user", {
            homeInfo: (state) => state.homeInfo,
        }),
    },
    data() {
        return {
            isRealName: this.isRealNameFlag
        }
    },
    mounted() {
    },
    methods: {
        // 用水业务跳转
        async waterService(data){
            if(data) {
                if (this.homeInfo && this.homeInfo.isBind) {
                    this.$router.push({
                        path: data.pathUrl,
                        name: data.componentName,
                        query: {
                            isRealName: this.isRealNameFlag
                        },
                    })
                } else {
                    this.$router.push({ path: '/Bind' })
                    await store.dispatch('user/savePathUrl', { url: data.pathUrl })
                }
            }
        },
    },
    watch: {
        isRealNameFlag:{
            handler: function (val, oldVal) {
                this.isRealName = val
            },
      		deep: true   // 深度监听，监听到更深层级值的变化
        }
    }
}
</script>

<style lang="less" scoped>
.myWater {
    background-color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 10px;
    .myWater-content {
        .title {
            display: inline-block;
            width: 100%;
            font-weight: 700;
            text-align: left;
            font-family: PingFangSC-Semibold;
            font-size: 16px;
            color: #222F45;
            letter-spacing: 0;
            margin: 15px 16px 10px 16px;
        }
        // 共有的
        .current-author-grid {
            padding: 7px 16px 20px 16px;
            em {
                display: inline-block;
                width: 44px;
                height: 44px;
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .name {
                font-family: PingFangSC-Regular;
                font-size: 12px;
                color: #5C6775;
                letter-spacing: 0;
            }
            i {
                position: absolute;
                top: 0px;
                right: 0px;
                border-top-left-radius: 100px;
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;
                padding: 1px 3px;
                font-style: normal;
                font-family: PingFangSC-Regular;
                font-size: 8px;
                color: #FFFFFF;
                letter-spacing: 0;
                 background-color: #5F97F4;
            }
        }
        .golden {
            i {
                background-color: #D5A864;
            }
        }
    }
}
</style>
