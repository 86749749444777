<template>
  <div class="feedback">
    <div class="feedback-content">
      <van-cell title="合同签署" is-link @click="gotoMyContract">
        <span>我的合同</span>
      </van-cell>

      <div class="feedback-info">
        <ul>
          <li :class="isRealNameFlag ? 'goldenLeftLi' : ''" @click="handerReport">
            <p style="position: relative;width:82px">
              未签合同
              <em v-if="unsignNum > 0" class="count">{{ unsignNum }}</em>
            </p>
            <em>请尽快签署</em>
          </li>
          <li :class="isRealNameFlag ? 'goldenRightLi' : ''" @click="handerProposal">
            <p>已签合同</p>
            <em>可查看签署结果</em>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
  name: 'FeedBack',
  props: {
    isRealNameFlag: {
      type: Boolean,
      require: true,
      default: () => {
        return false;
      },
    },
    contractNum: {
      type: Number,
      require: true,
      default: () => {
        return 0;
      },
    },
    unsignNum: {
      type: Number,
      require: true,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    handerReport() {
      this.$router.push({ path: '/Contract/MyContract', query: { isSign: 'unsign' } });
    },
    handerProposal() {
      this.$router.push({ path: '/Contract/MyContract', query: { isSign: 'signed' } });
    },
    gotoMyContract() {
      this.$router.push({ path: '/Contract/MyContract', query: { isSign: 'signed' } });
    },
  },
};
</script>

<style lang="less" scoped>
.feedback {
  background-color: #ffffff;
  padding-bottom: 18px;
  .feedback-content {
    text-align: left;
    margin-top: 10px;
    padding-top: 10px;
    .feedback-info {
      width: 100%;
      display: inline-block;
      background: #fff;
      ul {
        margin: 0px 16px;
        display: flex;
        li {
          display: inline-block;
          width: calc(100% / 2 - 10px);
          text-align: left;
          padding-left: 16px;
          padding-top: 27px;
          padding-bottom: 27px;
          p {
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #5c6775;
            letter-spacing: 0;
            margin: 0px;
          }
          em {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #b7c4d6;
            letter-spacing: 0;
            font-style: normal;
          }

          em.count {
            width: 20px;
            height: 20px;
            line-height: 20px;
            background-color: #ff5858;
            border-radius: 100px;
            font-size: 12px;
            font-style: normal;
            text-align: center;
            position: absolute;
            color: #ffffff;
            top: -2px;
            right: 0px;
          }
        }
        // 蓝色背景
        li:first-child {
          background: url('../../../assets/pic_banner_blue_left2@2x.png') no-repeat 90% 0;
          background-size: contain;
        }
        li:last-child {
          background: url('../../../assets/pic_banner_blue_right2@2x.png') no-repeat 90% 0;
          background-size: contain;
        }
        // 金色背景
        .goldenLeftLi:first-child {
          background: url('../../../assets/pic_banner_gold_left2@2x.png') no-repeat 90% 0;
          background-size: contain;
        }
        .goldenRightLi:last-child {
          background: url('../../../assets/pic_banner_gold_right2@2x.png') no-repeat 90% 0;
          background-size: contain;
        }
      }
    }
  }
}
</style>
